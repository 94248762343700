import React from 'react'
import { css } from '@emotion/react'

import GlobalStyle from '../styles/GlobalStyle'
import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'

import Size from '../styles/Size'
import { max959, min960 } from '../styles/Mixin'

const container = css`
  position: relative;
  margin: auto;
  ${max959} {
    width: 100%;
  }
  ${min960} {
    width: 960px;
  }
`

const contents = css`
  ${max959} {
    padding: ${Size(18.5)} ${Size(4)} ${Size(50)};
  }
  ${min960} {
    padding: ${Size(20)} 0;
  }
`
const NotFount = () => {
  return (
    <>
      <Head title={'404 Not Found'} />
      <GlobalStyle />
      <Header lower={true} />
      <article>
        <Title text="404" subTitle="Not Found" lower={true} />
        <div css={container}>
          <div css={contents}>
            <p
              css={css`
                font-size: 1.4rem;
                text-align: center;
              `}
            >
              Opps. Sorry, No Contents.
            </p>
          </div>
        </div>
      </article>
      <Footer />
    </>
  )
}

export default NotFount
